.container {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  background-color: #fff;
  min-height: 100VH;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0 0;
  flex: 0 0 auto; 
}
.main {
  display: flex;
  width: 350px;
  margin: 0 auto;
  align-items: center;
  position: relative;
  gap: 24px;
  flex-direction: column;
  padding: 80px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 12px 0 24px;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: auto;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0;
    margin-top: 64px;
    width: 92%;
  }
}